<template>
  <div id="feedbackbox" class="whitebox">
    <div>
      <div class="d-flex justify-content-between">

        <h2 v-if="justsent"> Vielen Dank</h2>
        <h2 v-else-if="!reminder">Danke für Ihr Interesse</h2>
        <h2 v-else-if="reminder"> Erinnerung einrichten</h2>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
             v-on:click="$emit('close')" style="cursor: pointer">
          <path d="M1 1L9 9M9 9L17 17M9 9L17 1M9 9L1 17" stroke="#B9B9B9" stroke-width="2"/>
        </svg>
      </div>
      <div v-if="justsent">
        Wir werden Ihnen in einigen Tagen eine freundliche Erinnerung per E-Mail schicken. Schauen Sie sich gern weiter
        in unserer Toolbox um.
      </div>
      <div v-else-if="!reminder">

        <p></p>
        <p>Wir würden uns sehr freuen, wenn Sie uns <strong>Ihr Feedback</strong> zur Toolbox und einzelnen Instrumenten
          mitteilen, damit wir sie in Zukunft noch verbessern können. Alternativ können wir Sie auch zu einem späteren
          Zeitpunkt <strong>erinnern</strong>, sobald Sie genug Erfahrungen mit den Instrumenten gemacht haben.</p>
      </div>
      <div v-else>
        <form v-on:submit.prevent="sendForm(data)">
          <div class="d-flex justify-content-between">
            <div style="margin: 10px 0;">
              <label for="vorname">Ihr Vorname</label> <br>
              <input type="text" v-model="data.vorname" id="vorname">
            </div>

            <div style="margin: 10px 0;">
              <label for="nachname">Ihr Nachname</label> <br>
              <input type="text" v-model="data.nachname" id="nachname">
            </div>
          </div>
          <label for="email" :class="{'red': $v.data.email.$invalid && errorsent}">Ihre E-Mail</label> <br>
          <input type="email" v-model="data.email" id="email">
          <div class="d-flex justify-content-between align-items-center" style="margin: 10px 0;">
            <div class="protection custom-control custom-switch d-flex align-items-center"
                 :class="{'red': $v.data.protection.$invalid && errorsent}">
              <input type="checkbox" class="custom-control-input" id="customSwitch" v-model="data.protection">
              <label class="custom-control-label" for="customSwitch" style="padding-top: 2px;">Datenschutz
                zustimmen</label>

            </div>
            <button type="submit">Abschicken</button>
          </div>
          <div v-if="errorsent" style="color:red">Bitte rote Felder ausfüllen</div>
          <div style="font-size: 12px">
            Mit dem Einrichten einer E-Mail-Erinnerung müssen Sie unseren Datenschutzrichtlinien zustimmen. Ihre Daten
            werden nicht an Dritte weitergegeben und wir werden Ihnen keine unerwünschten werbenden E-Mails oder Spam
            schicken. Sie können die Daten jederzeit löschen lassen.
          </div>
        </form>
      </div>
    </div>


    <div style="display: flex" v-if="!reminder">
      <button class="white" style="margin-right: 5px;" @click="reminder=true">
        Erinnern Sie mich später
      </button>
      <router-link tag="button" class="orange" to="/feedback" @click.native="$emit('close')">
        Jetzt Feedback geben
      </router-link>
    </div>
  </div>
</template>

<script>
import {checked, required, minLength, maxLength, email} from 'vuelidate/lib/validators'
import jQuery from "jquery";

export default {

  name: "Feedbackbox.vue",
  data() {
    return {
      reminder: false,
      data: {
        protection: false
      },
      justsent: false,
      errorsent: false

    }
  },
  validations: {
    data: {
      protection: {
        checked: value => value === true
      },
      email: {
        required
      },
    }
  },
  methods: {
    sendForm(data) {
      if (this.$v.data.$invalid) {
        this.errorsent = true;
        return false;
      }
      let strippeddata = JSON.parse(JSON.stringify(data))
      let url = process.env.NODE_ENV === 'development' ? 'https://toolbox-verpflegung.de/emailme' : '/emailme'
      console.log(data)
      jQuery.ajax({
        url: url,
        type: 'post',
        data: strippeddata,
        error: function () {
          this.errorsent = true
        }.bind(this)
      })
          .done(function (val) {
            try {
              if (JSON.parse(val).success) {
                this.justsent = true;


              } else this.errorsent = true;
            } catch (e) {
              console.log(e)
              this.errorsent = true
            }
          }.bind(this))

    }
  }
}
</script>

<style scoped lang="scss">
#feedbackbox {
  position: fixed;
  width: 435px;
  padding: 32px;
  right: 20px;
  @include mobile {
    right: 0;
    max-width: 100%;
  }
  bottom: 20px;
z-index: 1000;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}

.protection {
  &.red {
    input {

      border-color: red !important;
    }

    label {
      color: red;

    }
  }
}

label {
  margin-bottom: 0;

  &.red {
    color: red;

  }
}

</style>